<template>
  <div class="class-info">
    <!-- v-if="classList&& openClassList" -->
    <div class="tab_list">
      <div class="btns">
        <button class="button" :class="changeIndex == 1 ? 'blue' : 'grey'" @click="changeTab('1')">
          班级信息
        </button>
        <button class="button" :class="changeIndex == 2 ? 'blue' : 'grey'" @click="changeTab('2')">
          开放式班级
        </button>
      </div>
    </div>
    <!-- 班级信息 -->
    <div v-if="changeIndex == 1">
      <div class="center-title">
        <span>班级信息</span>
        <div class="drop-down" v-if="classSelect && classList" @mouseover="classListShow = true"
          @mouseleave="classListShow = false">
          <div class="down-title">{{ classSelect.name }}</div>
          <ul class="down-list" v-show="classListShow">
            <li class="down-info" v-for="item in classList" :key="item.id" @click="clickClass(item)">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="btns" v-if="classList.length > 0">
        <button class="button" :class="tabIndex == 1 ? 'blue' : 'grey'" @click="tableClick(1)">
          班级课程
        </button>
        <button class="button" :class="tabIndex == 2 ? 'blue' : 'grey'" @click="tableClick(2)">
          班级考试
        </button>
        <button class="button" :class="tabIndex == 3 ? 'blue' : 'grey'" @click="tableClick(3)">
          公开考试
        </button>
      </div>
      <div class="class_course" v-if="tabIndex == 1 && classList.length > 0">
        <div class="course_main">
          <div class="cont_warp">
            <ul>
              <li>
                <p class="label">课程进度</p>
                <div class="progress">
                  <div class="progress-bg">
                    <div class="progress-line" :style="{ width: classData.course_rate + '%' }"></div>
                  </div>
                  <div class="num">{{ classData.course_rate }}%</div>
                </div>
              </li>
              <li>
                <p class="label">学习有效期</p>
                <p class="text">
                  {{ classData.start_time }} 至 {{ classData.end_time }}
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p class="label">考试情况</p>
                <div class="icon_list">
                  <p class="">
                    <img src="@/assets/true.png" alt="" />
                    <span>{{ classData.exam_success }}项已通过</span>
                  </p>
                  <p class="">
                    <img src="@/assets/false.png" alt="" />
                    <span>{{ classData.exam_fail }}项已通过</span>
                  </p>
                </div>
              </li>
              <div class="flex">
                <li>
                  <p class="label">任务完成进度</p>
                  <p class="text">
                    {{ classData.finish_task }}/{{ classData.task_total }} 任务
                  </p>
                </li>
                <li class="down_cert" v-if="classData.cert_url && siteInfo.is_open_class_cert == 1">
                  <p class="label">证书下载</p>
                  <p class="text">
                    <a :href="classData.cert_url">证书下载</a>
                  </p>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <ul class="class-list">
          <li class="class-list-info" v-for="item in classCourse" :key="item.id">
            <router-link :to="'/recordedInfo/' + item.id">
              <img class="info-img" :src="item.image" alt="" />
              <div class="info-content">
                <div class="info-title vertical-ellipsis">{{ item.name }}</div>
                <div class="progress">
                  <div class="num">{{ item.rate }}</div>
                  <div class="progress-bg">
                    <div class="progress-line" :style="{ width: item.rate }"></div>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li class="no-data" v-if="classCourse.length == 0">
            班级内还没有课程
          </li>
        </ul>
      </div>
      <ul class="test-list" v-if="classSelect && classSelect.id == '302'">
        <li class="test-info">
          <div class="test-title vertical-ellipsis">培训心得</div>
          <div class="btns">
            <router-link :to="'/textarea'" class="button blue">去考试</router-link>
          </div>
        </li>
      </ul>

      <ul class="test-list" v-if="tabIndex == 2 && classList">
        <li class="test-info" v-for="item in classExam" :key="item.id">
          <div class="test-title vertical-ellipsis">{{ item.exam_name }}</div>
          <div class="test-date">
            {{ item.start_time }} — {{ item.end_time }}
          </div>
          <div class="btns">
            <!-- <button class="button blue" @click="toTest">去考试</button> -->
            <router-link :to="'/text/' + item.id + '/' + '1'" class="button blue">去考试</router-link>
            <button class="button grey" @click="viewScoreBtn(item.id, item.exam_name)">
              查看分数
            </button>
          </div>
          <div class="residue">
            剩余考试次数：<span class="num">{{ item.times - item.count }}次</span>
          </div>
        </li>
        <li class="no-data" v-if="classExam.length == 0 && (classSelect && classSelect.id != '302')">班级内还没有考试</li>
      </ul>
      <ul class="test-list" v-if="tabIndex == 3 && classList">
        <li class="test-info" v-for="item in classExam" :key="item.id">
          <div class="test-title vertical-ellipsis">{{ item.exam_name }}</div>
          <div class="test-date">
            {{ item.start_time }} — {{ item.end_time }}
          </div>
          <div class="btns">
            <!-- <button class="button blue" @click="toTest">去考试</button> -->
            <router-link :to="'/text/' + item.id + '/' + '1'" class="button blue">去考试</router-link>
            <button class="button grey" @click="viewScoreBtn(item.id, item.exam_name)">
              查看分数
            </button>
          </div>
          <div class="residue">
            剩余考试次数：<span class="num">{{ item.times - item.count }}次</span>
          </div>
        </li>
        <li class="no-data" v-if="classExam.length == 0 && (classSelect && classSelect.id != '302')">班级内还没有考试</li>
      </ul>
      <div class="view-score" v-if="viewScore && classList">
        <div class="score-content">
          <div class="close" @click="viewScore = false"></div>
          <div class="score-title">{{ viewScoreTitle }}</div>
          <ul class="my-table">
            <li class="table-th">
              <span class="table-td" style="width: 150px">id</span>
              <span class="table-td" style="width: 200px">考试时间</span>
              <span class="table-td" style="width: 180px">分数</span>
            </li>
            <li class="table-tr" v-for="(item) in queryScore.list" :key="item.created_at">
              <span class="table-td" style="width: 150px">{{ item.id }}</span>
              <span class="table-td" style="width: 200px">{{item.created_at}}</span>
              <span class="table-td" style="width: 180px">{{queryScore.is_show_score==1 ? item.score : '--'}}</span>
              <p class="btn" v-if="queryScore.is_show_score==1" @click="examDetail(item)">查看</p>
            </li>
            <li class="table-tr nodata" v-if="queryScore.list.length == 0">
              <span class="table-td">暂无分数</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 开放式班级 -->
    <div v-if="changeIndex == 2">
      <div class="center-title">
        <span>开放式班级</span>
        <!-- 班级下拉框选择 -->
        <div class="drop-down" v-if="classSelect && openClassList.length > 0" @mouseover="classListShow = true" @mouseleave="classListShow = false">
          <div class="down-title">{{ classSelect.name }}</div>
          <ul class="down-list" v-show="classListShow">
            <li class="down-info" v-for="item in openClassList" :key="item.id" @click="clickClass(item)">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <!-- 视频课程&文本课程&班级考试 选择按钮 -->
      <div class="btns" v-if="openClassList.length > 0">
        <button class="button" :class="tabIndex == 1 ? 'blue' : 'grey'" @click="tableClick(1)">
          视频课程
        </button>
        <button class="button" :class="tabIndex == 3 ? 'blue' : 'grey'" @click="tableClick(3)">
          文本课程
        </button>
        <button class="button" :class="tabIndex == 2 ? 'blue' : 'grey'" @click="tableClick(2)">
          班级考试
        </button>
      </div>
      <!-- 视频课程 -->
      <div class="class_course" v-if="tabIndex == 1 && openClassList.length > 0">
        <div class="course_main">
          <div class="cont_warp">
            <ul>
              <li>
                <p class="label">课程进度</p>
                <div class="progress">
                  <div class="progress-bg">
                    <div class="progress-line" :style="{ width: classData.course_rate + '%' }"></div>
                  </div>
                  <div class="num">{{ classData.course_rate }}%</div>
                </div>
              </li>
              <li>
                <p class="label">学习有效期</p>
                <p class="text">
                  {{ classData.start_time }} 至 {{ classData.end_time }}
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p class="label">考试情况</p>
                <div class="icon_list">
                  <p class="">
                    <img src="@/assets/true.png" alt="" />
                    <span>{{ classData.exam_success }}项已通过</span>
                  </p>
                  <p class="">
                    <img src="@/assets/false.png" alt="" />
                    <span>{{ classData.exam_fail }}项已通过</span>
                  </p>
                </div>
              </li>
              <div class="flex">
                <li>
                  <p class="label">任务完成进度</p>
                  <p class="text">
                    {{ classData.finish_task }}/{{ classData.task_total }} 任务
                  </p>
                </li>
                <!-- v-if="classData.cert_url && siteInfo.is_open_class_cert==1" -->
                <li class="down_cert" v-if="classData.cert_url">
                  <p class="label">证书下载</p>
                  <p class="text">
                    <a :href="classData.cert_url">证书下载</a>
                  </p>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <ul class="class-list">
          <li class="class-list-info" v-for="item in classCourse" :key="item.id">
            <router-link :to="'/recordedInfo/' + item.id">
              <img class="info-img" :src="item.image" alt="" />
              <div class="info-content">
                <div class="info-title vertical-ellipsis">{{ item.name }}</div>
                <div class="progress">
                  <div class="num">{{ item.rate }}</div>
                  <div class="progress-bg">
                    <div class="progress-line" :style="{ width: item.rate }"></div>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li class="no-data" v-if="classCourse.length == 0">
            班级内还没有课程
          </li>
        </ul>
      </div>
      <!-- 文本课程 -->
      <div class="text_course_list" v-if="tabIndex == 3 && textCourseList">
        <ul>
          <li v-for="item in textCourseList" :key="item.id" @click="goTextDetail(item.id)">
            <p class="img">
              <img :src="item.image" alt="">
            </p>
            <p class="text">{{ item.title }}</p>
          </li>
          <li class="no-data" v-if="textCourseList.length == 0">班级内还文本课程</li>
        </ul>
      </div>
      <!-- 班级考试 -->
      <ul class="test-list" v-if="tabIndex == 2 && openClassList">
        <li class="test-info" v-for="item in classExam" :key="item.id">
          <div class="test-title vertical-ellipsis">{{ item.exam_name }}</div>
          <div class="test-date">
            {{ item.start_time }} — {{ item.end_time }}
          </div>
          <div class="btns">
            <!-- <button class="button blue" @click="toTest">去考试</button> -->
            <router-link :to="'/text/' + item.id + '/' + '2'" class="button blue">去考试</router-link>
            <button class="button grey" @click="viewScoreBtn(item.id, item.exam_name)">
              查看分数
            </button>
          </div>
          <div class="residue">
            剩余考试次数：<span class="num">{{ item.times - item.count }}次</span>
          </div>
        </li>
        <li class="no-data" v-if="classExam.length == 0">班级内还没有考试</li>
      </ul>
      <div class="view-score" v-if="viewScore && openClassList">
        <div class="score-content">
          <div class="close" @click="viewScore = false"></div>
          <div class="score-title">{{ viewScoreTitle }}</div>
          <ul class="my-table">
            <li class="table-th">
              <span class="table-td" style="width: 150px">id</span>
              <span class="table-td" style="width: 200px">考试时间</span>
              <span class="table-td" style="width: 180px">分数</span>
            </li>
            <li class="table-tr" v-for="(item) in queryScore.list" :key="item.created_at">
              <span class="table-td" style="width: 150px">{{ item.id }}</span>
              <span class="table-td" style="width: 200px">{{item.created_at}}</span>
              <span class="table-td" style="width: 180px">{{queryScore.is_show_score==1 ? item.score : '--'}}</span>
              <p class="btn" v-if="queryScore.is_show_score==1" @click="examDetail(item)">查看</p>
            </li>
            <li class="table-tr nodata" v-if="queryScore.list.length == 0">
              <span class="table-td">暂无分数</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClassInfo",
  data() {
    return {
      tabIndex: 1, //标签类型
      classList: "", //班级列表
      openClassList: '',//开放式班级列表
      classListShow: false, //班级列表显示隐藏
      classSelect: "", //选中班级
      classCourse: "", //课程列表
      classExam: "", //考试列表
      viewScore: false, //查看分数弹窗
      viewScoreTitle: "", //弹窗标题
      queryScore: "", //分数数据
      classData: "",

      changeIndex: 1,
      siteInfo: '',
      textCourseList: [],//文本课程
    };
  },
  mounted() {
    if (localStorage.getItem('classTypeIndex')) {
      this.changeIndex = localStorage.getItem('classTypeIndex')
    }
    this.siteInfo = JSON.parse(localStorage.getItem('siteInfo')) ? JSON.parse(localStorage.getItem('siteInfo')) : '';

    if (this.changeIndex == '1') {
      this.getClassList(); //获取班级列表
    } else {
      this.getOpenClass(); //获取班级列表
    }
    // this.getOpenClass();//
  },
  methods: {
    changeTab(num) {
      this.changeIndex = num;
      this.tabIndex = "1";
      this.classList = []
      this.openClassList = []
      localStorage.setItem('classTypeIndex', num)
      this.classSelect = ''
      if (this.changeIndex == '1') {
        this.getClassList(); //获取班级列表
      } else {
        this.getOpenClass(); //获取班级列表
      }
    },
    //获取班级列表
    getClassList() {
      this.$axios
        .post(`/v1/personal/classList`, {}, { useLog: true })
        .then((res) => {
          if (res.code == 0) {
            if (res.data.list.length > 0) {
              this.classList = res.data.list;
              this.clickClass(res.data.list[0]);
            }
          } else {
            this.classList = []
            this.openClassList = []
            // this.changeIndex=2
          }


          // else{
          //   this.changeIndex=2
          //   this.getOpenClass()
          // }
        });
    },
    getOpenClass() {
      this.$axios
        .post(`/v1/openClass/classList`, {}, { useLog: true })
        .then((res) => {
          if (res.code == 0) {
            if (res.data.list.length > 0) {
              this.openClassList = res.data.list;
              this.clickClass(res.data.list[0]);
            }
          } else {
            this.classList = []
            this.openClassList = []
          }


          // else{
          //   this.changeIndex=1
          //    this.getClassList();
          // }
        });
    },
    // 获取文本课程
    getTextCourseList() {
      this.$axios.post(`/v1/openClass/classTextCourse`, { class_id: this.classSelect.id }, { useLog: true }).then(res => {
        if (res.code == 0) {
          this.textCourseList = res.data
        }
      })
    },
    // 文本课程-跳转到详情
    goTextDetail(id) {
      this.$router.push('/textCourse/detail?id=' + id)
    },
    getClassData(id) {
      let url = this.changeIndex == '1' ? '/v1/personal/myClass' : '/v1/openClass/myClass'

      this.$axios
        .post(
          url,
          {
            class_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          if (res.code == 0) {
            if (res.data.cert_url) {
              res.data.cert_url = res.data.cert_url.replace("http:", "https:");
            }
            this.classData = res.data;
            this.classCourse = res.data.courseList;
            this.classExam = res.data.examList;
          }
        });
    },
    //选择班级
    clickClass(item) {
      this.classSelect = item;
      this.classListShow = false;
      if (this.tabIndex == 1) {
        //课程
        // this.getClassCourse(item.id);
        this.getClassData(item.id);
      } else {
        //考试
        // this.getClassExam(item.id);
      }
      if (this.changeIndex == 2) {
        this.getTextCourseList()
        this.getClassData(this.classSelect.id);
      }
    },
    //获取班级课程
    getClassCourse(id) {
      let url = this.changeIndex == '1' ? '/v1/personal/classCourse' : '/v1/openClass/classCourse'

      this.$axios
        .post(
          url,
          {
            class_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          this.classCourse = res.data;
        });
    },
    //获取班级考试
    getClassExam(id) {
      let url = this.changeIndex == '1' ? '/v1/personal/classExam' : '/v1/openClass/classExam'

      this.$axios
        .post(url,
          {
            class_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          this.classExam = res.data;
        });
    },
    //课程/标签选择
    tableClick(index) {
      if (this.tabIndex != index) {
        this.tabIndex = index;

        if (index == 3) {
          // 文本课程
          this.getTextCourseList()
        } else {
          this.getClassData(this.classSelect.id);
        }
      }
    },
    //查看分数
    viewScoreBtn(id, name) {
      let url = this.changeIndex == '1' ? '/v1/personal/queryScore' : '/v1/openClass/queryScore'

      this.$axios
        .post(
          url,
          {
            exam_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          this.viewScore = true;
          this.viewScoreTitle = name;
          this.queryScore = res.data;
        });
    },
    // 查看考试详情
    examDetail(row) {
      this.$router.push('/userCenter/exam_detail?id=' + row.id + '&type=' + this.changeIndex)
    },
  },
};
</script>

<style scoped lang="scss">
.center-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  margin-bottom: 34px;

  .button {
    display: inline-block;
    text-align: center;
    margin-right: 14px;
  }
}

// 班级课程
.class_course {
  width: 788px;
  background: #ffffff;

  .course_main {
    width: 750px;
    height: 190px;
    background: #ffffff;
    box-shadow: 0px 0px 7px 1px rgba(0, 12, 40, 0.14);
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 50px;

    .cont_warp {
      display: flex;
      padding: 26px 30px;

      // justify-content: space-between;
      ul {
        flex: 1;

        li:first-child {
          margin-bottom: 36px;
        }

        li {
          p.label {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            padding-bottom: 10px;
          }

          .icon_list {
            display: flex;
            align-items: center;

            // justify-content: space-between;
            p {
              display: flex;
              align-items: center;
              flex: 1;

              span {
                padding-left: 5px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
              }
            }
          }

          p.text {
            font-size: 12px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
          }
        }

        .down_cert {
          margin-bottom: 36px;
          flex: 1;
          text-align: center;

          a {
            background: orange;
            color: #fff;
            padding: 6px 15px;
            border-radius: 5px;
            font-size: 14px;
            // font-;
          }
        }

        .progress {
          display: flex;
          align-items: center;

          .num {
            font-size: 12px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #999999;
            margin-left: 10px;
          }

          .progress-bg,
          .progress-line {
            width: 154px;
            height: 3px;
            background: #e5e5e5;
            box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
            border-radius: 3px;
          }

          .progress-line {
            background: #254ed4;
          }
        }
      }
    }
  }
}

.class-list,
.test-list {
  width: 750px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  &::after {
    content: "";
    display: block;
    width: 28%;
  }
}

.class-list-info {
  box-sizing: border-box;
  width: 230px;
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-bottom: 30px;

  .info-img {
    width: 100%;
    height: 142px;
  }

  .info-content {
    padding: 14px 20px 14px;
  }

  .info-title {
    height: 40px;
    font-size: 14px;
    color: #333;
    line-height: 20px;
  }

  .progress {
    padding-top: 14px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .num {
      font-size: 14px;
      color: #000;
      text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
    }

    .progress-bg,
    .progress-line {
      width: 154px;
      height: 3px;
      background: #e5e5e5;
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }

    .progress-line {
      background: #254ed4;
    }
  }
}

.test-info {
  box-sizing: border-box;
  width: 230px;
  background: #fff;
  padding: 24px 20px;
  box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  margin-bottom: 30px;

  .test-title {
    width: 100%;
    height: 40px;
    font-size: 14px;
    color: #333;
    line-height: 20px;
  }

  .test-date {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    color: #999999;
    padding-left: 32px;
    background: url(~@/assets/clock-ico.png) no-repeat left center #fff;
    background-size: 18px;
    margin-top: 12px;
  }

  .btns {
    padding-top: 34px;
    margin-bottom: 0;

    .button {
      padding: 0;
      width: 66px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      border-radius: 6px;
      margin-right: 34x;
    }
  }

  .residue {
    font-size: 10px;
    color: #999;
    margin-top: 8px;

    .num {
      color: #666;
    }
  }
}

.drop-down {
  height: 20px;
  position: relative;

  .down-title,
  .down-info {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #666;
    font-weight: normal;
    padding-right: 18px;
    background: url(~@/assets/arrows.png) no-repeat right center #fff;
    background-size: 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .down-list {
    padding-top: 8px;
    position: absolute;
    top: 20px;
    right: 0;
    background: #fff;
    box-shadow: 1px 1px 10px #999;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
  }

  .down-info {
    background: none;
    margin-bottom: 5px;
  }
}

.view-score {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99;

  .score-content {
    width: 600px;
    max-height: 600px;
    padding: 30px;
    background-color: #fff;
    position: relative;

    .score-title {
      font-size: 24px;
      font-weight: 900;
      color: #333;
      margin-bottom: 20px;
    }

    .close {
      width: 30px;
      height: 30px;
      background: url(~@/assets/close-ico.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: -50px;
      top: 0;
      cursor: pointer;
    }

    p.btn {
      cursor: pointer;
      color: #254ed4;
    }
  }
}

.my-table {
  max-height: 500px;
  overflow-y: auto;

  .table-th,
  .table-tr {
    height: 50px;

    &.nodata {
      width: 100%;
      justify-content: center;
    }
  }
}

.no-data {
  color: #999;
}

// 文本课程
.text_course_list {
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 230px;
      margin-right: 30px;

      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
      margin-bottom: 25px;
      border-radius: 8px;
      padding-bottom: 20px;

      p.img {
        width: 100%;
        height: 150px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }
      }

      p.text {
        box-sizing: border-box;
        padding: 0 20px;
        font-size: 14px;
        margin-top: 10px;

      }
    }

    li.no-data {
      box-shadow: none;
    }

    li:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
